import React from "react";
import { Table } from "reactstrap";
import SessionsTableRow from "./SessionsTableRow/SessionsTableRow";

const SessionsTable = ({ headers, data, handleDeleteItem, handleTranscript }) => (
<>   
<Table className="align-items-center table-flush" responsive>
    <thead className="thead-light">
      <tr>{headers?.map((header, id) => <th scope="col" key={"session-table-header-"+id}>{header}</th>)}</tr>
    </thead>
    <tbody>
        {
        data?.map((items) => <SessionsTableRow onTranscript={() => handleTranscript(items?.filename)} onDelete={() => handleDeleteItem(items?.filename)} {...items} />)
        }
    </tbody>
</Table>
{
!data?.length && <div className="container text-center py-4">No Data</div>
}
</> 
)

export default SessionsTable;