import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";

const TranscriptPopup = (props) => {
  const [ transcript, setTranscript ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const { isOpen, name } = props

  useEffect(() => {
    if (!transcript && isOpen && name) {    
      setLoading(true)
      fetch(process.env.REACT_APP_FILES+"conversation_"+name+".txt")
        .then((response) => {
          return response.text();
        })
        .then((data) => {
          setLoading(false)
          setTranscript(data)
        })
        .catch(() => setLoading(false));
    } else if (!isOpen) {
      setTranscript(null)
    }
  }, [transcript, isOpen, name])

  return (
  <Modal {...props}>
    <ModalHeader toggle={props.toggle}>Transcript</ModalHeader>
    <ModalBody style={{ fontSize: 16, paddingBottom: 0, paddingTop: 0, color: "#212529", minHeight: 64, overflowY: "auto", textAlign: loading ? "center" : "left" }}>
    {
      loading ? <Spinner
        className="text-center"
        color="primary"
        size="md"
      >
        Loading...
      </Spinner> : 
      <textarea name="prompt" className="p-0 form-control" style={{ minHeight: 260, background: "#FFFFFF", border: "none" }} value={transcript} readOnly/>
    }
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={props.toggle}>
        Close
      </Button>
    </ModalFooter>
  </Modal>
)}

export default TranscriptPopup