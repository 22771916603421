import {
  Col,
  Container,
  Row
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import SessionsTable from "components/Tables/SessionsTable/SessionsTable";
import { useCallback, useEffect, useState } from "react";
import SessionsService from "../../services/SessionsService";
import ConfirmPopup from "components/Popups/ConfirmPopup";
import TranscriptPopup from "components/Popups/TranscriptPopup";

const ApiService = new SessionsService()

const headers = [
  "Date",
  "Transcript",
  "Record",
  "Name",
  "Delete"
]

const Sessions = () => {
  const [ data, setData ] = useState(null);
  const [ selected, setSelected ] = useState(null);
  const [ transcript, setTranscript ] = useState(null);

  const handleToggleDelete = () => setSelected(!selected);
  const handleDeleteOpen = (name) => setSelected(name);

  const handleToggleTranscript = () => setTranscript(!transcript);
  const handleTranscriptOpen = (transcript) => setTranscript(transcript);

  const resetRecords = useCallback(() => {
    try {    
      const id = parseInt(localStorage.getItem("atidot-back-office-user"))
      ApiService._postRecords({"user_id" : id}, 
        (response) => {
          const res = response.data
          ?.filter(({ filename }) => filename && /[.]mp3|wav$/gm.test(filename))
          ?.map((item) => ({
            ...item,
            filename: item.filename.slice(13, item.filename.indexOf(".")),
            audio: new Audio(process.env.REACT_APP_FILES+item.filename)
          }))
          setData(res?.reverse())
          setSelected(null)
        }
      )
    } catch (error) {
      setSelected(null)
      console.log(error);
    }
  }, [])

  const handleDeleteRecord = () => {
    ApiService._deleteRecord({"file_name": "conversation_"+selected+".wav"},
      () => {
        resetRecords()
      } 
    )
  }

  useEffect(() => {
    resetRecords()
  }, [resetRecords])

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col xl="12">
            <SessionsTable headers={headers} data={data} handleDeleteItem={handleDeleteOpen} handleTranscript={handleTranscriptOpen}/>

            <ConfirmPopup size="sm" action="Delete" centered isOpen={selected ? true : false} toggle={handleToggleDelete} confirm={handleDeleteRecord}>
              Are you sure you want to delete the record?
            </ConfirmPopup>

            <TranscriptPopup centered isOpen={transcript ? true : false} toggle={handleToggleTranscript} name={transcript}/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Sessions;
