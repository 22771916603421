import { useState, useEffect } from "react";

const useAudio = (audio) => {
    const [ playing, setPlaying ] = useState(false);
    const [ duration, setDuration ] = useState(0);
    const [ progress, setProgress ] = useState(0);

    const toggle = () => {
        if(/[.]mp3|wav$/gm.test(audio.currentSrc)) {
            try {
                playing ? audio.pause() : audio.play();
                setPlaying(!playing);
            } catch (error) {
                setPlaying(false)
                console.log(error);
            }
        }
    }

    const update = (e) => {
        const time = Math.round(e.target.value)
        audio.currentTime = time
        setProgress(time)
    }

    useEffect(() => {
        audio.addEventListener("timeupdate", () => setProgress(audio.currentTime))
        audio.addEventListener("loadedmetadata", () => setDuration(audio.duration))
        audio.addEventListener("ended", () => {
            setPlaying(false)
            setProgress(0)
        });
        return () => {
            audio.removeEventListener('ended', () => {
                setPlaying(false)
                setProgress(0)
            });
        }
    }, [audio])

    return [ playing ? "playing" : "paused", progress, duration, toggle, update ];
}

export default useAudio;