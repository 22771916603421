import axios from "axios";

class ApiService {
    authBearer = localStorage.getItem("atidot-back-office-token");

    getDefaultHeaders = (token) => {
        return token ? { "Authorization": token } : {}
    }

    handleTokenExpire = (response) => {
        if(response.status === 401 && response.data?.error?.toLowerCase() === "token has expired") {
            window.location.assign("/auth/login")
        }
    }

    post = (url, data, headers = {}, onResolve = () => {}, onReject = () => {}) => {
        const defaultHeaders = this.getDefaultHeaders(this.authBearer)
        axios.post(process.env.REACT_APP_API+url, data, {
            headers: {
                ...defaultHeaders, 
                ...headers
            }
        })
            .then((response) => onResolve(response))
            .catch((error) => {
                onReject(error);
                console.log(error);
                this.handleTokenExpire(error.response)
            })
    }

    get = (url, headers = {}, onResolve = () => {}, onReject = () => {}) => {
        const defaultHeaders = this.getDefaultHeaders(this.authBearer)
        
        axios.get(process.env.REACT_APP_API+url, {
            headers: {
                ...defaultHeaders, 
                ...headers
            }
        })
            .then((response) => onResolve(response))
            .catch((error) => {
                onReject(error);
                console.log(error);
                this.handleTokenExpire(error.response)
            })
    }

    delete = (url, data, headers = {}, onResolve = () => {}, onReject = () => {}) => {
        const defaultHeaders = this.getDefaultHeaders(this.authBearer)
        axios.delete(process.env.REACT_APP_API+url, {
            headers: {
                ...defaultHeaders, 
                ...headers
            },
            data
        })
            .then((response) => onResolve(response))
            .catch((error) => {
                onReject(error);
                console.log(error);
                this.handleTokenExpire(error.response)
            })
    }

    login = (data, onResolve = () => {}, onReject = () => {}) => {
        this.post("/login", data, {}, (response) => {
            this.authBearer = "Bearer " + response.data.access_token;
            localStorage.setItem("atidot-back-office-token", this.authBearer);
            localStorage.setItem("atidot-back-office-user", response.data.user_id);
            localStorage.setItem("atidot-back-office-name", response.data.name);
            localStorage.setItem("robin_public_access_token", response.data.public_access_token);
            localStorage.setItem("robin_store_url", response.data.store);
            localStorage.setItem("robin_test_mode", response.data.enable);
            onResolve()
        }, onReject)
    }

}

export default ApiService =  new ApiService();