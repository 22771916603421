import ApiService from "./service";

export default class UserService {
    _getUserStats = (handleResponse = () => {}, handleError = () => {}) => ApiService.get("/user/user_stats", {}, handleResponse, handleError)

    _getTotalStats = (handleResponse = () => {}, handleError = () => {}) => ApiService.get("/user/total_stats", {}, handleResponse, handleError)

    _getEventCount = (handleResponse = () => {}, handleError = () => {}) => ApiService.get("/user/stats/event_count", {}, handleResponse, handleError)

    _getPurchasesConversionRate = (handleResponse = () => {}, handleError = () => {}) => ApiService.get("/user/stats/purchase_conversion_rate", {}, handleResponse, handleError)
}