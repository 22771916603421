import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const ConfirmPopup = (props) => (
  <Modal {...props}>
    <ModalBody style={{ fontSize: 16, paddingBottom: 0, color: "#212529" }}>{props.children}</ModalBody>
    <ModalFooter>
      <Button color="danger" onClick={props.confirm}>
        {props.action}
      </Button>{' '}
      <Button color="secondary" onClick={props.toggle}>
        Cancel
      </Button>
    </ModalFooter>
  </Modal>
)

export default ConfirmPopup